import { css, keyframes } from '@creditas-ui/system'
import { media, selectTheme } from '@creditas-ui/utilities'

const animationKeyframes = {
  info: keyframes`
    from {
      opacity: 0;
      transform: translateY(24px);
    } 
    
    to {
      opacity: 1;
      transform: translateY(0);
    }
  `,
  card1: keyframes`
    from {
      transform: translateX(40px);
    } 
    
    to {
      opacity: 1;
      transform: translateX(0);
    }
  `,
  card2: keyframes`
    from {
      transform: translateX(48px);
    } 
    
    to {
      transform: translateX(0);
      opacity: 1;
    }
  `,
}

const animationKeyframesMd = {
  info: keyframes`
    from {
      transform: translateX(-16px);
    } 
    
    to {
      transform: translateX(0);
      opacity: 1;
    }
  `,
  card1: keyframes`
    from {
      transform: translateX(16px);
    } 
    
    to {
      transform: translateX(0);
      opacity: 1;
    }
  `,
  card2: keyframes`
    from {
      transform: translateX(24px);
    } 
    
    to {
      transform: translateX(0);
      opacity: 1;
    }
  `,
}

const getFadeInAnimationKeyFrameOptions = (keyframeName, animate, theme) => css`
  opacity: 0;
  animation: ${animationKeyframes[keyframeName]}
    ${selectTheme('motion.lg')({ theme })} ease-in-out 1ms forwards;

  ${media.up('5xl')} {
    animation: ${animationKeyframesMd[keyframeName]}
      ${selectTheme('motion.lg')({ theme })} ease-in-out 1ms forwards;
  }

  animation-play-state: ${animate ? 'running' : 'paused'};
`

export { getFadeInAnimationKeyFrameOptions }
