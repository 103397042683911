import { css, styled } from '@creditas-ui/system'
import { media, selectTheme } from '@creditas-ui/utilities'

import { Container as CreditasContainer } from 'components/Container'
import { Grid as CreditasGrid } from 'components/Grid'

const Section = styled.section(
  ({ bgColor, theme }) => css`
    padding-top: ${selectTheme('spacingTop.16xl')({ theme })};
    padding-bottom: ${selectTheme('spacingBottom.21xl')({ theme })};

    ${media.up('5xl')} {
      padding-top: ${selectTheme('spacingTop.21xl')({ theme })};
    }
    background-color: ${selectTheme(`colors.${bgColor}`)({ theme })};
  `,
)

const Container = styled(CreditasContainer)`
  box-sizing: border-box;
`

const Grid = styled(CreditasGrid)`
  grid-template-rows: max-content;

  grid-template-areas:
    '${'content '.repeat(4)}'
    '${'products '.repeat(4)}';

  ${media.up('2xl')} {
    grid-template-areas:
      '${'content '.repeat(8)}'
      '${'products '.repeat(8)}';
  }

  ${media.up('5xl')} {
    grid-template-areas: '${'content '.repeat(6)} ${'products '.repeat(6)}';
  }
`

export { Section, Container, Grid }
