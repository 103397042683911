import { styled } from '@creditas-ui/system'
import { media } from '@creditas-ui/utilities'

import { Cta } from 'components/Cta'

const StyledCta = styled(Cta)`
  display: none;
  width: fit-content;
  text-decoration: none;
  transition: box-shadow 0.3s ease-in-out;

  ${media.up('5xl')} {
    display: flex;
  }
`

export { StyledCta }
