import React from 'react'
import { arrayOf, shape } from 'prop-types'

import Stamp, { propTypes as stampPropTypes } from 'components/Stamp'

import { Stamps } from './styles'

const propTypes = {
  stamps: arrayOf(shape(stampPropTypes)),
}

const defaultProps = {
  stamps: [],
}

const ProductList = ({ stamps, ...others }) => {
  return (
    <Stamps {...others}>
      {stamps?.map(stamp => (
        <Stamp key={stamp.id} {...stamp} />
      ))}
    </Stamps>
  )
}

ProductList.propTypes = propTypes
ProductList.defaultProps = defaultProps

export default ProductList
