import { styled } from '@creditas-ui/system'
import { getTypography, media, selectTheme } from '@creditas-ui/utilities'

import CreditasTypography from '../Typography'

const Typography = styled(CreditasTypography)`
  text-transform: uppercase;
  margin-bottom: ${selectTheme('spacingBottom.3xl')};
  ${getTypography('exceptionSmBold')}

  ${media.up('5xl')} {
    ${getTypography('exceptionMdBold')}
  }
`

export { Typography }
