import { css, styled } from '@creditas-ui/system'
import { getTypography, media, selectTheme } from '@creditas-ui/utilities'

import CreditasTypography from '../Typography'

const Typography = styled(CreditasTypography)(
  ({ theme, variant }) => css`
    margin-bottom: ${selectTheme('spacingBottom.10xl')({ theme })};
    ${getTypography(variant ?? 'bodyLgRegular')({ theme })}

    ${media.down('5xl')} {
      ${getTypography('bodyLgRegular')({ theme })}
    }
  `,
)

export { Typography }
