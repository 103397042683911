import { styled } from '@creditas-ui/system'
import { media } from '@creditas-ui/utilities'

const Stamps = styled.div`
  margin-top: 120px;

  ${media.down('5xl')} {
    display: none;
  }

  > div {
    margin-bottom: 20px;
  }
`

export { Stamps }
