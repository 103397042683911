import { css, styled } from '@creditas-ui/system'
import { media, selectTheme } from '@creditas-ui/utilities'

import { getFadeInAnimationKeyFrameOptions } from '../../base.styles'

const Products = styled.div(
  ({ animate, smallCards, theme }) => css`
    grid-area: products;
    list-style: none;
    width: 100%;
    display: grid;
    align-items: end;
    grid-template-columns: repeat(2, 272px) 1px;
    grid-template-rows: minmax(380px, max-content);
    grid-gap: 0 ${selectTheme('spacingX.3xl')({ theme })};
    overflow-x: auto;

    ${smallCards &&
    css`
      grid-template-columns: repeat(2, 232px) 1px;
    `}

    padding-bottom: ${selectTheme('spacingBottom.6xl')({ theme })};
    padding-top: ${selectTheme('spacingTop.6xl')({ theme })};
    padding-left: ${selectTheme('spacingLeft.3xl')({ theme })};

    &::after {
      content: '';
      height: 1px;
    }

    ${media.up('5xl')} {
      overflow-x: visible;
      grid-template-columns: repeat(2, minmax(min-content, 288px));
      grid-gap: 0 ${selectTheme('spacingX.6xl')({ theme })};
      padding-left: 0;
      padding-top: 0;

      &::after {
        content: none;
        height: 0;
      }

      & > li:first-child {
        margin-left: 0;
      }
    }

    & > li:nth-child(1) {
      ${getFadeInAnimationKeyFrameOptions('card1', animate, theme)}
    }

    & > li:nth-child(2) {
      ${getFadeInAnimationKeyFrameOptions('card2', animate, theme)}
    }
  `,
)

export { Products }
