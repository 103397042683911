import React from 'react'
import { node, object, string } from 'prop-types'

import { Container, Grid, Section } from './styles'

const propTypes = {
  bgColor: string,
  children: node,
  currentSectionRef: object,
}

const defaultProps = {
  bgColor: 'neutral.90',
}

const Wrapper = ({ children, currentSectionRef, ...others }) => {
  return (
    <Section {...others} ref={currentSectionRef}>
      <Container>
        <Grid>{children}</Grid>
      </Container>
    </Section>
  )
}

Wrapper.propTypes = propTypes
Wrapper.defaultProps = defaultProps

export default Wrapper
