import { css, styled } from '@creditas-ui/system'
import { media, selectTheme } from '@creditas-ui/utilities'

import { getFadeInAnimationKeyFrameOptions } from '../../base.styles'

const Container = styled.div(
  ({ animate, theme }) => css`
    grid-area: content;
    padding-right: ${selectTheme('spacingRight.3xl')({ theme })};
    padding-left: ${selectTheme('spacingLeft.3xl')({ theme })};

    ${media.up('5xl')} {
      padding-right: 0;
      padding-left: 0;
    }

    ${getFadeInAnimationKeyFrameOptions('info', animate, theme)}
  `,
)

export { Container }
