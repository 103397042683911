import React from 'react'
import { string } from 'prop-types'

import { ButtonPrimaryWithArrow } from '@creditas-ui/button'
import { useTriggerEvents } from 'hooks/useTriggerEvents'

import { propTypes as ctaPropTypes } from 'components/Cta'

import { StyledCta } from './styles'

const propTypes = {
  buttonCta: ctaPropTypes,
  label: string,
}

const defaultProps = {
  buttonCta: {},
}

const ButtonCta = ({ buttonCta, label, ...others }) => {
  const { triggerEvent } = useTriggerEvents()

  if (!buttonCta) return null

  const analyticEvent = {
    label: `${label} | ${buttonCta?.url}`,
    pageInteraction: buttonCta?.type !== 'link',
  }

  return (
    <StyledCta
      element={ButtonPrimaryWithArrow}
      width="flexible"
      size="large"
      {...buttonCta}
      {...others}
      onClick={() => triggerEvent(analyticEvent)}
    >
      {label}
    </StyledCta>
  )
}

ButtonCta.propTypes = propTypes
ButtonCta.defaultProps = defaultProps

export default ButtonCta
