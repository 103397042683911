import React from 'react'
import { bool, node } from 'prop-types'

import { Container } from './styles'

const propTypes = {
  animate: bool,
  children: node,
}

const defaultProps = {
  animate: true,
}

const InfoContainer = ({ children, ...others }) => {
  return <Container {...others}>{children}</Container>
}

InfoContainer.propTypes = propTypes
InfoContainer.defaultProps = defaultProps

export default InfoContainer
