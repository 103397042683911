import React from 'react'
import { arrayOf, bool, oneOf, shape, string } from 'prop-types'

import ProductCard, { propTypes as productCardPropTypes } from '../ProductCard'

import { Products } from './styles'

const propTypes = {
  animate: bool,
  boxShadow: string,
  products: arrayOf(shape(productCardPropTypes)),
  smallCards: bool,
  variant: oneOf(['white', 'green']),
}

const defaultProps = {
  animate: true,
  products: [],
  smallCards: false,
  variant: 'white',
}

const ProductList = ({
  boxShadow,
  products,
  smallCards,
  variant,
  ...others
}) => {
  return (
    <Products smallCards={smallCards} {...others}>
      {products?.map((product, index) => (
        <ProductCard
          boxShadow={boxShadow}
          key={product.id}
          variant={variant}
          index={index}
          {...product}
        />
      ))}
    </Products>
  )
}

ProductList.propTypes = propTypes
ProductList.defaultProps = defaultProps

export default ProductList
