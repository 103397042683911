import React from 'react'
import { arrayOf, bool, number, object, oneOf, shape, string } from 'prop-types'

import { useTriggerEvents } from 'hooks/useTriggerEvents'

import { Cta, propTypes as ctaPropTypes } from 'components/Cta'

import ProductCardCTA from '../ProductCardCTA'

import {
  Container,
  Content,
  Description,
  Feature,
  FeatureDescription,
  FeatureIcon,
  FeatureList,
  HeaderImage,
  Title,
  Wrapper,
} from './styles'

export const propTypes = {
  boxShadow: string,
  cta: ctaPropTypes,
  ctaLabel: string,
  description: string,
  displayCtaAsButton: bool,
  features: arrayOf(
    shape({
      icon: object,
      text: string,
    }),
  ),
  headerImageDesktop: object,
  headerImageMobile: object,
  index: number,
  title: string,
  variant: oneOf(['white', 'green']),
}

const defaultProps = {
  variant: 'white',
  displayCtaAsButton: false,
  features: [],
}

const ProductCard = prop => {
  const {
    boxShadow,
    cta,
    ctaLabel,
    description,
    displayCtaAsButton,
    features,
    headerImageDesktop,
    headerImageMobile,
    index,
    title,
    variant,
  } = prop
  const currentVariant = variant || defaultProps.variant
  const hasButton = displayCtaAsButton ?? defaultProps.displayCtaAsButton

  const headerImages = [
    {
      ...headerImageDesktop?.fluid,
      description: headerImageDesktop?.description,
      media: '(min-width: 960px)',
      sizes: '(max-width: 1920px)',
    },
    {
      ...headerImageMobile?.fluid,
      description: headerImageMobile?.description,
      sizes: '(max-width: 1024px)',
    },
  ]

  const { triggerEvent } = useTriggerEvents()

  const analyticEvent = {
    label: `${index} | ${ctaLabel} | ${cta?.url}`,
    pageInteraction: cta?.type !== 'link',
  }

  return (
    <Wrapper data-testid="product-comparison-card">
      <Cta {...cta} onClick={() => triggerEvent(analyticEvent)}>
        {/* Had to change variant name here so it does not conflict
         with prop variant of webcard */}
        <Container styleVariant={currentVariant} boxShadow={boxShadow}>
          <HeaderImage objectFit="contain" artDirection={headerImages} />
          <Content>
            {title && (
              <Title
                data-testid="product-comparison-card-title"
                variant={currentVariant}
                dangerouslySetInnerHTML={{ __html: title }}
              />
            )}
            {description && (
              <Description
                variant={currentVariant}
                data-testid="product-comparison-card-description"
              >
                {description}
              </Description>
            )}
            {features && (
              <FeatureList>
                {features.map(({ icon, id, text }) => (
                  <Feature
                    key={id}
                    data-testid="product-comparison-card-feature"
                    hasIcon={!!icon}
                  >
                    {icon && <FeatureIcon {...icon} />}
                    <FeatureDescription variant={currentVariant}>
                      {text}
                    </FeatureDescription>
                  </Feature>
                ))}
              </FeatureList>
            )}
            <ProductCardCTA ctaLabel={ctaLabel} hasButton={hasButton} />
          </Content>
        </Container>
      </Cta>
    </Wrapper>
  )
}

ProductCard.propTypes = propTypes
ProductCard.defaultProps = defaultProps

export default ProductCard
