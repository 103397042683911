import React from 'react'
import { bool, string } from 'prop-types'

import { ButtonPrimaryTextOnly } from '@creditas-ui/button'

import { Action, StyledChevronIcon } from './styles'

const propTypes = {
  ctaLabel: string,
  hasButton: bool,
}

const ProductCardCTA = ({ ctaLabel, hasButton, ...others }) => {
  if (!ctaLabel) return null

  return hasButton ? (
    <ButtonPrimaryTextOnly as="span" {...others}>
      {ctaLabel}
    </ButtonPrimaryTextOnly>
  ) : (
    <Action {...others}>
      {ctaLabel}
      <StyledChevronIcon />
    </Action>
  )
}

ProductCardCTA.propTypes = propTypes

export default ProductCardCTA
