import { css, styled } from '@creditas-ui/system'
import { getTypography, media, selectTheme } from '@creditas-ui/utilities'

import CustomIcon from 'components/CustomIcon'
import { LazyImage } from 'components/LazyImage'

import Typography from '../Typography'

const getVariantStyles = theme => ({
  white: {
    card: css`
      background-color: ${selectTheme('colors.neutral.0')({ theme })};
      box-shadow: ${selectTheme('shadow.sm')({ theme })};
    `,
    title: css`
      color: ${selectTheme('colors.neutral.90')({ theme })};
    `,
    description: css`
      color: ${selectTheme('colors.neutral.80')({ theme })};
    `,
    featureText: css`
      color: ${selectTheme('colors.neutral.80')({ theme })};
    `,
  },
  green: {
    card: css`
      background-color: ${selectTheme('colors.neutral.80')({ theme })};
      box-shadow: ${selectTheme('shadow.sm')({ theme })};
    `,
    title: css`
      color: ${selectTheme('colors.neutral.0')({ theme })};
    `,

    description: css`
      color: ${selectTheme('colors.neutral.0')({ theme })};
    `,
    featureText: css`
      color: ${selectTheme('colors.neutral.0')({ theme })};
    `,
  },
})

const Wrapper = styled.li`
  height: 100%;
  min-height: 452px;

  a {
    text-decoration: none;
    height: 100%;
  }
`

const Container = styled.div(({ boxShadow, styleVariant, theme }) => {
  const containerStyles = getVariantStyles(theme)[styleVariant].card

  return css`
    height: 100%;
    padding: 0;
    border-radius: ${selectTheme('radius.6xl')({ theme })};
    border-color: transparent;
    overflow: hidden;
    position: relative;
    cursor: pointer;
    transition: box-shadow ${selectTheme('motion.xs')({ theme })} ease-in-out;
    display: flex;
    flex-direction: column;

    ${containerStyles}

    ${boxShadow &&
    `box-shadow: ${selectTheme(`shadow.${boxShadow}`)({ theme })};`}

    &:hover {
      ${containerStyles};
      box-shadow: ${selectTheme('shadow.sm')({ theme })};
      border-color: transparent;

      ${media.up('5xl')} {
        box-shadow: ${selectTheme('shadow.lg')({ theme })};
      }
    }
  `
})

const Content = styled.div`
  flex: 1;
  padding: ${selectTheme('spacingY.6xl')} ${selectTheme('spacingX.6xl')}
    ${selectTheme('spacingY.7xl')};
  display: flex;
  flex-direction: column;

  ${media.up('5xl')} {
    padding: ${selectTheme('spacingY.8xl')} ${selectTheme('spacingX.6xl')}
      ${selectTheme('spacingY.9xl')};
  }
`

const HeaderImage = styled(LazyImage)`
  position: absolute;
  height: 144px;
`

const Title = styled(Typography)(
  ({ theme, variant }) => css`
    margin-bottom: ${selectTheme('spacingBottom.6xl')({ theme })};
    ${getTypography('headingXsLight')({ theme })};
    ${getVariantStyles(theme)[variant].title}
  `,
)

const Description = styled(Typography)(
  ({ theme, variant }) => css`
    margin-bottom: ${selectTheme('spacingBottom.9xl')({ theme })};
    ${getTypography('bodySmRegular')({ theme })};
    ${getVariantStyles(theme)[variant].description}
  `,
)

const FeatureList = styled.ul`
  flex: 1;
  list-style: none;
  margin-bottom: ${selectTheme('spacingBottom.9xl')};

  ${media.up('5xl')} {
    margin-bottom: ${selectTheme('spacingBottom.12xl')};
  }
`

const Feature = styled.li(
  ({ hasIcon, theme }) => css`
    display: flex;

    p {
      ${getTypography('bodySmRegular')({ theme })};
    }

    & + li {
      margin-top: ${selectTheme('spacingTop.6xl')({ theme })};
    }

    ${media.up('5xl')} {
      & + li {
        margin-top: ${selectTheme(
          hasIcon ? 'spacingTop.7xl' : 'spacingTop.3xl',
        )({ theme })};
      }
    }
  `,
)

const FeatureIcon = styled(CustomIcon)`
  margin-right: ${selectTheme('spacingX.4xl')};
  width: 24px;
  height: 24px;
`

const FeatureDescription = styled(Typography)(
  ({ theme, variant }) => css`
    ${getVariantStyles(theme)[variant].featureText}
  `,
)

export {
  Container,
  HeaderImage,
  Content,
  Title,
  Description,
  FeatureList,
  Feature,
  FeatureIcon,
  FeatureDescription,
  Wrapper,
}
