import React from 'react'
import { object, string } from 'prop-types'

import { ImageElement, Title, Wrapper } from './styles'

export const propTypes = {
  image: object,
  title: string,
}

const defaultProps = {
  title: '',
}

const Stamp = ({ image, title, ...others }) => {
  return (
    <Wrapper {...others}>
      {image && <ImageElement {...image} />}
      {title && (
        <Title color="neutral.80" variant="bodyXsRegular">
          {title}
        </Title>
      )}
    </Wrapper>
  )
}

Stamp.propTypes = propTypes
Stamp.defaultProps = defaultProps

export default Stamp
