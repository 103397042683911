/* eslint-disable react/jsx-no-literals */
import React, { useCallback, useRef, useState } from 'react'
import { arrayOf, object, oneOf, shape, string } from 'prop-types'

import useTriggerOnScroll from 'utils/useTriggerOnScroll'

import { propTypes as stampPropTypes } from 'components/Stamp'

import { propTypes as productCardPropTypes } from './components/ProductCard'
import Template01 from './Template01'
import Template02 from './Template02'
import Template03 from './Template03'
import Template04 from './Template04'
import Template05 from './Template05'

const propTypes = {
  buttonCta: object,
  buttonCtaLabel: string,
  'data-testid': string,
  description: string,
  preTitle: string,
  preTitleHeadingTag: oneOf(['h1', 'h2', 'h3', 'h4', 'h5', 'h6']),
  products: arrayOf(shape(productCardPropTypes)),
  stamps: arrayOf(shape(stampPropTypes)),
  title: string,
  titleHeadingTag: oneOf(['h1', 'h2', 'h3', 'h4', 'h5', 'h6']),
  variant: oneOf([
    'Template01',
    'Template02',
    'Template03',
    'Template04',
    'Template05',
  ]),
}

const defaultProps = {
  preTitleHeadingTag: 'h1',
  titleHeadingTag: 'h2',
  'data-testid': 'section-productcomparison',
  variant: 'Template01',
  products: [],
}

const ELEMENT_VARIANTS = {
  Template01,
  Template02,
  Template03,
  Template04,
  Template05,
}

const ProductComparison = ({
  preTitleHeadingTag,
  titleHeadingTag,
  variant,
  ...others
}) => {
  variant = variant || defaultProps.variant
  const titleAs = titleHeadingTag || defaultProps.titleHeadingTag
  const preTitleAs = preTitleHeadingTag || defaultProps.preTitleHeadingTag

  const Element = ELEMENT_VARIANTS[variant]

  const [isSeen, setSeen] = useState(false)
  const currentSectionRef = useRef(null)

  const handleAnimate = useCallback(() => {
    setSeen(true)
  }, [])

  useTriggerOnScroll(currentSectionRef, handleAnimate)

  return (
    <Element
      {...others}
      variant={variant}
      isSeen={isSeen}
      currentSectionRef={currentSectionRef}
      titleAs={titleAs}
      preTitleAs={preTitleAs}
    />
  )
}

ProductComparison.propTypes = propTypes
ProductComparison.defaultProps = defaultProps

export default ProductComparison
