import React from 'react'
import { string } from 'prop-types'

import { Typography } from './styles'

const propTypes = {
  color: string,
  content: string,
}

const defaultProps = {
  color: 'neutral.90',
}

const PreTitle = ({ content, ...others }) => {
  if (!content) return null
  return <Typography {...others}>{content}</Typography>
}

PreTitle.propTypes = propTypes
PreTitle.defaultProps = defaultProps

export default PreTitle
