import { styled } from '@creditas/stylitas'
import { Typography } from '@creditas-ui/typography'

import { LazyImage } from 'components/LazyImage'

const Wrapper = styled.div`
  display: flex;
  align-items: center;
`

const Title = styled(Typography)``

const ImageElement = styled(LazyImage)`
  width: 28px;
  margin-right: 10px;
`

export { ImageElement, Title, Wrapper }
