import ChevronRight from '@creditas/icons/dist/navigation/ChevronRight'
import { css, styled } from '@creditas-ui/system'
import { getTypography, selectTheme } from '@creditas-ui/utilities'

const Action = styled.span(
  ({ theme }) => css`
    display: flex;
    align-items: center;
    color: ${selectTheme('colors.primary.60')({ theme })};

    ${getTypography('bodyMdMedium')({ theme })}
  `,
)

const StyledChevronIcon = styled(ChevronRight)(
  ({ theme }) => css`
    fill: ${selectTheme('colors.primary.60')({ theme })};
    width: 24px;
  `,
)

export { Action, StyledChevronIcon }
