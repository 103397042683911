import React from 'react'
import { arrayOf, bool, object, oneOf, shape, string } from 'prop-types'

import { propTypes as stampPropTypes } from 'components/Stamp'

import Description from '../components/Description'
import InfoContainer from '../components/InfoContainer'
import PreTitle from '../components/PreTitle'
import { propTypes as productCardPropTypes } from '../components/ProductCard'
import ProductList from '../components/ProductList'
import StampList from '../components/StampList'
import Title from '../components/Title'
import Wrapper from '../components/Wrapper'

const propTypes = {
  currentSectionRef: object,
  'data-testid': string,
  description: string,
  isSeen: bool,
  preTitle: string,
  preTitleAs: oneOf(['h1', 'h2', 'h3', 'h4', 'h5', 'h6']),
  products: arrayOf(shape(productCardPropTypes)),
  stamps: arrayOf(shape(stampPropTypes)),
  title: string,
  titleAs: oneOf(['h1', 'h2', 'h3', 'h4', 'h5', 'h6']),
}

const defaultProps = {
  preTitleAs: 'h1',
  titleAs: 'h2',
  'data-testid': 'section-productcomparison',
  products: [],
  stamps: [],
  isSeen: true,
}

const Template05 = ({
  currentSectionRef,
  description,
  isSeen,
  preTitle,
  preTitleAs,
  products,
  stamps,
  title,
  titleAs,
  ...others
}) => {
  return (
    <Wrapper
      {...others}
      currentSectionRef={currentSectionRef}
      data-testid="section-product-comparison-Template05"
      bgColor="neutral.0"
      smallCards
    >
      <InfoContainer animate={isSeen}>
        <PreTitle color="neutral.60" as={preTitleAs} content={preTitle} />
        <Title color="neutral.90" as={titleAs} content={title} />
        <Description
          color="neutral.80"
          variant="bodyXlLight"
          content={description}
        />
        {stamps?.length > 0 && (
          <StampList
            data-testid="section-product-comparison-stamps"
            stamps={stamps}
          />
        )}
      </InfoContainer>

      <ProductList
        variant="white"
        boxShadow="xs"
        smallCards
        animate={isSeen}
        products={products}
      />
    </Wrapper>
  )
}

Template05.propTypes = propTypes
Template05.defaultProps = defaultProps

export default Template05
