import React from 'react'
import { string } from 'prop-types'

import { Typography } from './styles'

const propTypes = {
  color: string,
  content: string,
}

const defaultProps = {
  color: 'neutral.90',
}

const Description = ({ content, ...others }) => {
  if (!content) return null
  return (
    <Typography
      data-testid="description"
      dangerouslySetInnerHTML={{ __html: content }}
      {...others}
    />
  )
}

Description.propTypes = propTypes
Description.defaultProps = defaultProps

export default Description
