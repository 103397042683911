import React from 'react'
import { string } from 'prop-types'

import { Typography } from './styles'

const propTypes = {
  color: string,
  content: string,
}

const defaultProps = {
  color: 'neutral.90',
}

const Title = ({ content, ...others }) => {
  if (!content) return null
  return (
    <Typography dangerouslySetInnerHTML={{ __html: content }} {...others} />
  )
}

Title.propTypes = propTypes
Title.defaultProps = defaultProps

export default Title
