import { css, styled } from '@creditas-ui/system'
import { selectTheme } from '@creditas-ui/utilities'

const Typography = styled.p(
  ({ color = 'neutral.90', theme }) => css`
    color: ${selectTheme(`colors.${color}`)({ theme })};
  `,
)

export default Typography
