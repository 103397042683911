import { styled } from '@creditas-ui/system'
import { getTypography, media, selectTheme } from '@creditas-ui/utilities'

import CreditasTypography from '../Typography'

const Typography = styled(CreditasTypography)`
  margin-bottom: ${selectTheme('spacingBottom.6xl')};
  ${getTypography('headingLgLight')};

  ${media.up('5xl')} {
    ${getTypography('headingXlLight')};
  }
`

export { Typography }
