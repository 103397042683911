import React from 'react'
import { arrayOf, bool, object, oneOf, shape, string } from 'prop-types'

import ButtonCta from '../components/ButtonCta'
import Description from '../components/Description'
import InfoContainer from '../components/InfoContainer'
import PreTitle from '../components/PreTitle'
import { propTypes as productCardPropTypes } from '../components/ProductCard'
import ProductList from '../components/ProductList'
import Title from '../components/Title'
import Wrapper from '../components/Wrapper'

const propTypes = {
  buttonCta: object,
  buttonCtaLabel: string,
  currentSectionRef: object,
  'data-testid': string,
  description: string,
  isSeen: bool,
  preTitle: string,
  preTitleAs: oneOf(['h1', 'h2', 'h3', 'h4', 'h5', 'h6']),
  products: arrayOf(shape(productCardPropTypes)),
  title: string,
  titleAs: oneOf(['h1', 'h2', 'h3', 'h4', 'h5', 'h6']),
}

const defaultProps = {
  preTitleAs: 'h1',
  titleAs: 'h2',
  'data-testid': 'section-productcomparison',
  products: [],
  isSeen: true,
}

const Template01 = ({
  buttonCta,
  buttonCtaLabel,
  currentSectionRef,
  description,
  isSeen,
  preTitle,
  preTitleAs,
  products,
  title,
  titleAs,
  ...others
}) => {
  return (
    <Wrapper
      {...others}
      currentSectionRef={currentSectionRef}
      data-testid="section-product-comparison-Template01"
      bgColor="neutral.90"
    >
      <InfoContainer animate={isSeen}>
        <PreTitle color="neutral.0" as={preTitleAs} content={preTitle} />
        <Title color="neutral.0" as={titleAs} content={title} />
        <Description color="neutral.0" content={description} />
        <ButtonCta buttonCta={buttonCta} label={buttonCtaLabel} />
      </InfoContainer>

      <ProductList variant="white" animate={isSeen} products={products} />
    </Wrapper>
  )
}

Template01.propTypes = propTypes
Template01.defaultProps = defaultProps

export default Template01
